<template>
  <AdminView>
    <h1 class="title-1">Pathologies & symptômes</h1>

    <div class="search">
      <Input title="Rechercher" v-model="search" />
    </div>

    <PathologyForm mode="add" @addPathology="addPathology" />

    <PathologyForm
      v-for="pathology in filteredPathologies"
      :key="pathology.id"
      :pathology="pathology"
      @editPathology="editPathology"
      @removePathology="openModal"
      mode="edit"
    />
    <ConfirmationModal
      v-if="isOpenDeleteModal"
      @close="isOpenDeleteModal = false"
      @confirm="remove"
    />
  </AdminView>
</template>

<script>
import { mapGetters } from "vuex"
import AdminView from "@/components/AdminView"
import PathologyForm from "@/components/Forms/PathologyForm"
import Input from "@/components/Forms/Fields/Input"
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal.vue"

export default {
  name: "ProgramsList",
  components: {
    AdminView,
    PathologyForm,
    Input,
    ConfirmationModal
  },
  data() {
    return {
      search: "",
      isOpenDeleteModal: false,
      idToDelete: 0
    }
  },
  async created() {
    await this.$store.dispatch("admin/getPathologies")
  },
  methods: {
    async remove() {
      await this.$store.dispatch("admin/removePathologie", this.idToDelete)
      this.isOpenDeleteModal = false
    },
    openModal(id) {
      this.idToDelete = id
      this.isOpenDeleteModal = true
    },
    async addPathology(data) {
      await this.$store.dispatch("admin/addPathologie", data)
    },
    async editPathology(name, id) {
      await this.$store.dispatch("admin/editPathologie", { name, id })
    }
  },
  computed: {
    ...mapGetters({
      pathologies: "admin/pathologies"
    }),
    filteredPathologies() {
      return this.pathologies.filter((p) =>
        p.name.toLowerCase().includes(this.search.toLowerCase())
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  margin-bottom: 20px;

  > div {
    flex-grow: 0;
  }
}
</style>
