<template>
  <div class="pathology-form">
    <Input class="title" v-model="pathologyName" />

    <Button
      v-if="edited && mode === 'edit'"
      title="Modifier"
      type="primary"
      @click="editPathology"
    />

    <Button
      v-if="mode === 'edit'"
      title="Supprimer"
      type="warning"
      @click="removePathology"
    />

    <Button v-if="mode === 'add'" title="Ajouter" type="primary" @click="addPathology">
      <SVGPlus />
    </Button>
  </div>
</template>

<script>
import _ from "lodash"
import Input from "@/components/Forms/Fields/Input"
import Button from "@/components/Button"
import SVGPlus from "@/assets/icons/plus.svg"

export default {
  name: "PathologyForm",
  props: {
    pathology: {
      type: Object,
      default() {
        return {
          name: ""
        }
      }
    },
    mode: {
      type: String,
      default: "edit"
    }
  },
  components: {
    Input,
    Button,
    SVGPlus
  },
  data() {
    return {
      edited: false,
      editablePathology: {}
    }
  },
  created() {
    this.editablePathology = _.cloneDeep(this.pathology)
  },
  computed: {
    pathologyName: {
      get() {
        return this.editablePathology.name
      },
      set(name) {
        this.editablePathology.name = name
      }
    }
  },
  methods: {
    removePathology() {
      this.$emit("removePathology", this.pathology.id)
    },
    addPathology() {
      this.$emit("addPathology", this.pathologyName)
      this.pathologyName = ""
    },
    editPathology() {
      this.$emit("editPathology", this.pathologyName, this.pathology.id)
    }
  },
  watch: {
    pathologyName() {
      this.edited = true
    }
  }
}
</script>

<style lang="scss" scoped>
.pathology-form {
  display: flex;
  align-items: center;

  .title {
    flex-grow: 1;
  }
}
</style>
